import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { AboutUsInfoSection } from '../sections/AboutUs/AboutUsInfoSection';
import { AboutUsReadyToStartSaving } from '../sections/AboutUs/ReadyToStartSaving';
import { IsoHero } from '../sections/Iso/Hero';

export const Iso = () => {
	return (
		<MainLayout
			title="ISO 27001 Certified Salary Sacrifice Provider | Secure & Trusted"
			description="Gogeta is ISO 27001 certified, ensuring top data security in salary sacrifice schemes. Ethical, secure, and the only BCorp certified provider in the industry."
		>
			<ScPage>
				<IsoHero />
				<AboutUsInfoSection />
				<AboutUsReadyToStartSaving />
			</ScPage>
		</MainLayout>
	);
};

export default Iso;
