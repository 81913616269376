import styled from 'styled-components';

export const ScIsoHeroTextColumns = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		p {
			font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		}
	}
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
`;
