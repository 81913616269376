import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv, MotionImage } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/invite-employee.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { useMatchMedia } from '../../hooks/use-match-media';
import {
	ScReadyToStartSavingButtonsWrapper,
	ScReadyToStartSavingTextWrapper,
} from '../Home/styled';

import { ScAboutUsReadyToStartSavingWrapper } from './styled';

export const AboutUsReadyToStartSaving = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScContainer>
				<ScAboutUsReadyToStartSavingWrapper>
					<ScReadyToStartSavingTextWrapper>
						<MotionDiv variants={fadeInRight}>
							<ScTitle isMobileLarge color={theme.colors.strongWhite} centered={!isDesktop}>
								Ready to {isDesktop && <br />}
								start saving?
							</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInRight}>
							<ScParagraph color={theme.colors.strongWhite} centered={!isDesktop}>
								<p>Let’s make it a good work day.</p>
								<p>
									Whether you’re an employee, an employer or a partner, you can start using Gogeta
									in just a few clicks. Go on, use your loaf.
								</p>
							</ScParagraph>
						</MotionDiv>
						{!isDesktop && (
							<MotionImage variants={fadeInUp} src={image} alt="ready to start saving image" />
						)}
						<MotionDiv variants={fadeInRight}>
							<ScReadyToStartSavingButtonsWrapper ref={ref}>
								<Link to="/employee-product/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.strongWhite}>
										I’m an employee
									</CustomButton>
								</Link>
								<Link to="/employer-product/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.strongWhite}>
										I’m an employer
									</CustomButton>
								</Link>
								<Link to="/provider-pathway/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.strongWhite}>
										I’m a partner
									</CustomButton>
								</Link>
							</ScReadyToStartSavingButtonsWrapper>
						</MotionDiv>
					</ScReadyToStartSavingTextWrapper>
					{isDesktop && (
						<MotionImage variants={fadeInLeft} src={image} alt="ready to start saving image" />
					)}
				</ScAboutUsReadyToStartSavingWrapper>
			</ScContainer>
		</motion.section>
	);
};
