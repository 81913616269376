import React from 'react';

import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import image from '../../assets/images/iso-logo.png';
import { ScContainer } from '../../components/container/styled';
import { useMatchMedia } from '../../hooks/use-match-media';
import { ScAboutUsHeroContainer, ScAboutUsHeroWrapper } from '../AboutUs/styled';
import { ScBcorpHeroContent } from '../BCorp/styled';

import { ScIsoHeroTextColumns } from './styled';

export const IsoHero = () => {
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<ScAboutUsHeroContainer>
			<Top />
			<ScAboutUsHeroWrapper>
				<ScContainer>
					<ScBcorpHeroContent>
						<h3>ISO Certified</h3>
						<p>Gogeta is ISO 27001 certified</p>
						<ScIsoHeroTextColumns>
							<div>
								<p>
									Helping people get an extra slice of their salary with the power of salary
									sacrifice might seem like magic but with our watertight approach to data
									protection there is no smoke and mirrors.
								</p>
								<p>
									We are proud to be ISO 27001 certified, in recognition of our industry leading
									information protection procedures and protocols.
								</p>
								<p>
									ISO27001 is a globally recognised standard that provides a framework for an
									information security management system. This is incredibly important{' '}
									{!isDesktop &&
										'when you are dealing with salary sacrifice and payroll. To achieve ISO 27001 certification, we demonstrated our robust approach to managing customer and employee data, financial information and security controls.'}
								</p>
							</div>
							<div>
								{isDesktop && (
									<p>
										when you are dealing with salary sacrifice and payroll. To achieve ISO 27001
										certification, we demonstrated our robust approach to managing customer and
										employee data, financial information and security controls.
									</p>
								)}
								<p>
									We’re the only salary sacrifice benefits provider that is both a BCorp and ISO
									27001 and we think that’s pretty special. When you choose to work with us, you
									choose a company that has been recognised not only for its ethical approach to
									doing business but also its commitment to customer and data protection.
								</p>
							</div>
						</ScIsoHeroTextColumns>
						<img src={image} alt="hand" />
					</ScBcorpHeroContent>
				</ScContainer>
			</ScAboutUsHeroWrapper>
			<Bot />
		</ScAboutUsHeroContainer>
	);
};
