import styled from 'styled-components';

export const ScBcorpHeroContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 40px 0 70px 0;
	box-sizing: border-box;
	max-width: 1200px;
	margin: 0 auto;
	> img {
		width: 260px;
		max-width: 100%;
		height: auto;
		margin: 24px auto 0 auto;
	}
	h3 {
		font: ${({ theme }) => theme.fonts.heading.medium};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
		color: ${({ theme }) => theme.colors.darkText};
	}
	p {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.paragraph.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
`;

export const ScBcorpText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media (min-width: 768px) {
		gap: 40px;
	}
`;

export const ScBcorpTextBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	h3 {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallTitle};
		margin-left: 5px;
	}
`;

export const ScBcorpTextBlockContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		p {
			color: ${({ theme }) => theme.colors.darkText};
			font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		}
	}
	b {
		font-family: 'GeomanistMedium';
	}
	img {
		width: 40%;
		margin: 0 auto;
		height: auto;
	}
	@media (min-width: 768px) {
		img {
			width: 146px;
			height: auto;
			margin: auto;
		}
	}
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
`;
