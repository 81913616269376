import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { CustomButton } from '../../components/custom-button';
import { ScAboutUsHeroInfoButtons } from '../../sections/AboutUs/styled';
import { InfoSection } from '../../sections/EmployeeCycling/InfoSection';

export const AboutUsInfoSection = () => {
	const theme = useTheme();

	return (
		<InfoSection
			title="FAQs"
			text="Got more questions? We’ve got you covered."
			buttons={
				<ScAboutUsHeroInfoButtons>
					<Link to="/employee-faq/">
						<CustomButton
							hoverBackground={theme.colors.strongWhite}
							hoverColor={theme.colors.darkText}
						>
							For Employees
						</CustomButton>
					</Link>
					<Link to="/employer-faq/">
						<CustomButton
							hoverBackground={theme.colors.strongWhite}
							hoverColor={theme.colors.darkText}
						>
							For Employers
						</CustomButton>
					</Link>
				</ScAboutUsHeroInfoButtons>
			}
		/>
	);
};
