import styled from 'styled-components';

export const ScAboutUsHeroContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	svg {
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}

	@media (min-width: 768px) {
		margin-top: 85px;
	}
	@media (min-width: 1220px) {
		margin-top: 105px;
	}
`;

export const ScAboutUsHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.white};
`;

export const ScAboutUsHeroContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: 40px 0 70px 0;
	box-sizing: border-box;
	max-width: 1200px;
	margin: 0 auto;
	h3 {
		font: ${({ theme }) => theme.fonts.heading.medium};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.large};
		}
		color: ${({ theme }) => theme.colors.darkText};
	}
	p {
		color: ${({ theme }) => theme.colors.darkText};
		font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.paragraph.medium};
		}
		@media (min-width: 1220px) {
			font: ${({ theme }) => theme.fonts.paragraph.large};
		}
	}
`;

export const ScAboutUsHeroPeach = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	background: ${({ theme }) => theme.colors.peach};
	h3 {
		text-align: center;
		width: fit-content;
		height: fit-content;
		font: ${({ theme }) => theme.fonts.heading.medium};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.heading.xl};
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	padding: 20px;
	border-radius: 10px;
	@media (min-width: 768px) {
		img {
			width: 70%;
		}
	}
	@media (min-width: 1220px) {
		display: grid;
		align-items: center;
		grid-template-columns: 35fr 55fr;
		padding: 60px 140px;
		border-radius: 30px;
		img {
			width: 100%;
		}
	}
	@media (min-width: 1920px) {
		display: grid;
		align-items: center;
		grid-template-columns: 35fr 55fr;
		padding: 60px 140px;
		border-radius: 30px;
	}
`;

export const ScAboutUsHeroTextColumns = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
	div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		p {
			font: ${({ theme }) => theme.fonts.aboutUs.smallText};
		}
	}
	@media (min-width: 1220px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
	}
`;

export const ScAboutUsHeroInfoButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
	}
`;

export const ScAboutUsReadyToStartSavingWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	p {
		max-width: 100% !important;
	}
	img {
		width: 225px;
		height: auto;
	}
	@media (min-width: 768px) {
		img {
			width: 60%;
		}
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 40px 60px;
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: 150px;
		img {
			width: 100%;
			height: auto;
		}
	}
`;
